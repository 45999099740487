import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Game.css';
import { HelpCircle, Info, X } from 'lucide-react';
import { IoMdHelpCircleOutline, IoMdInformationCircleOutline, IoMdClose  } from "react-icons/io";

const API_BASE_URL = 'https://clickdle.onrender.com';
// Game.js
//const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function Game() {
  const [dailyMouse, setDailyMouse] = useState(null);
  const [guess, setGuess] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [showTopView, setShowTopView] = useState(false);
  const [showSideView, setShowSideView] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [brandLogoUrl, setBrandLogoUrl] = useState('');


  
  const fetchDailyMouse = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/mice/daily`);
      console.log('Daily mouse data:', response.data);
      setDailyMouse(response.data);
      
      if (response.data && response.data.brand) {
        const safeFileName = response.data.brand.replace(/\s+/g, '_').toLowerCase();
        const logoUrl = `${API_BASE_URL}/assets/logos/${safeFileName}.jpg`;
        console.log('Constructed logo URL:', logoUrl);
        setBrandLogoUrl(logoUrl);
      }
    } catch (error) {
      console.error('Error fetching daily mouse:', error);
    }
  };

  useEffect(() => {
    const loadGameState = () => {
      const savedState = localStorage.getItem('clickdleGameState');
      if (savedState) {
        const { date, dailyMouse, guesses, gameOver } = JSON.parse(savedState);
        const savedDate = new Date(date);
        const nowPST = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        
        if (isSameDay(savedDate, nowPST)) {
          setDailyMouse(dailyMouse);
          setGuesses(guesses);
          setGameOver(gameOver);
          return true;
        }
      }
      return false;
    };

    if (!loadGameState()) {
      fetchDailyMouse();
    }
  }, []);

  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

  const saveGameState = (dailyMouse, guesses, gameOver) => {
    const gameState = {
      date: new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}),
      dailyMouse,
      guesses,
      gameOver
    };
    localStorage.setItem('clickdleGameState', JSON.stringify(gameState));
  };

  const handleInputChange = async (e) => {
    const input = e.target.value;
    setGuess(input);

    if (input.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/mice/search?name=${input}`);
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = async (selectedMouse) => {
    setGuess(selectedMouse.name);
    setSuggestions([]);
    await handleGuess(null, selectedMouse);
  };

  const handleGuess = async (e, selectedMouse = null) => {
    if (e) e.preventDefault();
    if (guesses.length >= 8 || gameOver) return;

    try {
      let guessedMouse;
      if (selectedMouse) {
        guessedMouse = selectedMouse;
      } else {
        const response = await axios.get(`${API_BASE_URL}/api/mice/search?name=${guess}`);
        guessedMouse = response.data && response.data.length > 0 ? response.data[0] : null;
      }

      if (!guessedMouse) {
        alert('Mouse not found. Please try again.');
        return;
      }

      const newGuess = compareGuess(guessedMouse);
      const updatedGuesses = [...guesses, newGuess];
      setGuesses(updatedGuesses);
      setGuess('');

      const isGameOver = newGuess.name.correct || updatedGuesses.length >= 8;
      setGameOver(isGameOver);
      saveGameState(dailyMouse, updatedGuesses, isGameOver);
    } catch (error) {
      console.error('Error fetching mouse:', error);
    }
  };

  const compareGuess = (guessedMouse) => {
    return {
      name: {
        value: guessedMouse.name,
        correct: guessedMouse.name === dailyMouse.name,
      },
      brand: {
        value: guessedMouse.brand,
        correct: guessedMouse.brand === dailyMouse.brand,
      },
      weight: {
        value: guessedMouse.weight,
        correct: guessedMouse.weight === dailyMouse.weight,
        close: Math.abs(guessedMouse.weight - dailyMouse.weight) <= 10,
        direction: guessedMouse.weight < dailyMouse.weight ? 'up' : 'down',
      },
      shape: {
        value: guessedMouse.shape,
        correct: guessedMouse.shape === dailyMouse.shape,
      },
      thumbRest: {
        value: guessedMouse.thumbRest,
        correct: guessedMouse.thumbRest === dailyMouse.thumbRest,
      },
      connectivity: {
        value: guessedMouse.connectivity,
        correct: guessedMouse.connectivity === dailyMouse.connectivity,
      },
      dpi: {
        value: guessedMouse.dpi,
        correct: guessedMouse.dpi === dailyMouse.dpi,
        close: Math.abs(guessedMouse.dpi - dailyMouse.dpi) <= 5000,
        direction: guessedMouse.dpi < dailyMouse.dpi ? 'up' : 'down',
      },
      fourK: {
        value: guessedMouse.pollingRate >= 4000,
        correct: (guessedMouse.pollingRate >= 4000) === (dailyMouse.pollingRate >= 4000),
      },
      sideButtons: {
        value: guessedMouse.sideButtons,
        correct: guessedMouse.sideButtons === dailyMouse.sideButtons,
        direction: guessedMouse.sideButtons < dailyMouse.sideButtons ? 'up' : 'down',
      },
      middleButtons: {
        value: guessedMouse.middleButtons,
        correct: guessedMouse.middleButtons === dailyMouse.middleButtons,
        direction: guessedMouse.middleButtons < dailyMouse.middleButtons ? 'up' : 'down',
      },
    };
  };

  const renderGuessItem = (item, key) => {
    let displayValue = item.value;
    if (typeof item.value === 'boolean') {
      displayValue = item.value ? 'Yes' : 'No';
    }
    
    let arrow = '';
    if (!item.correct && item.direction) {
      arrow = item.direction === 'up' ? ' ↑' : ' ↓';
    }

    return (
      <div key={key} className={`guess-item ${item.correct ? 'correct' : item.close ? 'close' : ''}`}>
        {displayValue}{arrow}
      </div>
    );
  };

  const renderGuesses = () => {
    return guesses.map((guess, index) => (
      <div key={index} className="guess-row">
        {renderGuessItem(guess.name, 'name')}
        {renderGuessItem(guess.brand, 'brand')}
        {renderGuessItem(guess.weight, 'weight')}
        {renderGuessItem(guess.shape, 'shape')}
        {renderGuessItem(guess.thumbRest, 'thumbRest')}
        {renderGuessItem(guess.connectivity, 'connectivity')}
        {renderGuessItem(guess.dpi, 'dpi')}
        {renderGuessItem(guess.fourK, '4k')}
        {renderGuessItem(guess.sideButtons, 'sideButtons')}
        {renderGuessItem(guess.middleButtons, 'middleButtons')}
      </div>
    ));
  };

  const getSvgFilename = (view) => {
    if (!dailyMouse) {
      console.log('dailyMouse is null or undefined');
      return '';
    }

    const safeName = dailyMouse.name.replace(/[^a-zA-Z0-9-]/g, '_');
    const filename = `${safeName}_${view}.svg`;
    console.log('Daily mouse:', dailyMouse);
    console.log('Generated filename:', filename);
    return filename;
  };



  const toggleHelp = () => {
    setShowHelp(!showHelp);
    setShowAbout(false);
  };

  const toggleAbout = () => {
    setShowAbout(!showAbout);
    setShowHelp(false);
  };



  return (
    <div className="app-container">
      <div className="top-bar">
        <button className="icon-button" onClick={toggleHelp}>
          <IoMdHelpCircleOutline size={24} />
        </button>
        <button className="icon-button" onClick={toggleAbout}>
          <IoMdInformationCircleOutline size={24} />
        </button>
      </div>
  
      {showHelp && (
        <div className="dropdown-content help-content">
          <div className="dropdown-header">
            <h2>How to Play</h2>
            <button className="close-button" onClick={() => setShowHelp(false)}>
              <X size={24} />
            </button>
          </div>
          <ol>
            <li>Try to guess the mouse in 8 guesses.</li>
            <li><span className="green">Green</span> is an exact match.</li>
            <li><span className="yellow">Yellow</span> is a close match. A close...
              <ol type="a">
                <li>Weight is off by at most 10.</li>
                <li>DPI is off by at most 5000.</li>
              </ol>
            </li>
            <li>Click the show X buttons for a hint.</li>
            <li>New mice are released every day at midnight! (PST)</li>
          </ol>
        </div>
      )}
  
      {showAbout && (
        <div className="dropdown-content about-content">
          <div className="dropdown-header">
            <h2>About</h2>
            <button className="close-button" onClick={() => setShowAbout(false)}>
              <X size={24} />
            </button>
          </div>
          <p>A game for mouse enthusiasts and casual gamers alike! Inspired by Wordle, Poeltl, and all the other spinoffs.</p>
          <p>Each day a new mouse is picked from a curated list.</p>
          <p>All rights go to the rightful owners - no copyright infringement intended.</p>
          <p>Contact me: aldenjimenezgonzalez@gmail.com</p>
          {/* <p className="version">#123 | abc4567</p> */}

        </div>
      )}
  
      <div className="game-container">
        <h1>Clickdle.</h1>
        <form onSubmit={handleGuess}>
          <div className="input-container">
            <input
              type="text"
              value={guess}
              onChange={handleInputChange}
              placeholder="Guess a mouse..."
            />
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion._id}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </form>
        <div className="button-container">
          <button onClick={() => setShowBrand(!showBrand)}>
            {showBrand ? 'Hide Brand' : 'Show Brand'}
          </button>
          <button onClick={() => setShowTopView(!showTopView)}>
            {showTopView ? 'Hide Top View' : 'Show Top View'}
          </button>
          <button onClick={() => setShowSideView(!showSideView)}>
            {showSideView ? 'Hide Side View' : 'Show Side View'}
          </button>
        </div>
        <div className="content-row">
        {showBrand && (
        <div className="content-item brand-hint">
          {brandLogoUrl && (
            <img 
              src={brandLogoUrl}
              alt={`${dailyMouse?.brand} logo`}
              onError={(e) => {
                console.error("Error loading brand logo:", e);
                console.log("Attempted logo URL:", brandLogoUrl);
                e.target.style.display = 'none';
                e.target.nextSibling.style.display = 'block';
              }}
            />
          )}
          <span style={{display: brandLogoUrl ? 'none' : 'block'}}>{dailyMouse?.brand}</span>
        </div>
          )}
          {showTopView && (
            <div className="content-item svg-view">
              <img 
                src={`${API_BASE_URL}/assets/svgs/${getSvgFilename('top')}`}
                alt="Top view" 
                onError={(e) => {
                  console.error("Error loading top view SVG:", e);
                  console.log("Attempted SVG path:", e.target.src);
                  e.target.style.display = 'none';
                }}
              />
            </div>
          )}
          {showSideView && (
            <div className="content-item svg-view">
              <img 
                src={`${API_BASE_URL}/assets/svgs/${getSvgFilename('side')}`}
                alt="Side view" 
                onError={(e) => {
                  console.error("Error loading side view SVG:", e);
                  console.log("Attempted SVG path:", e.target.src);
                  e.target.style.display = 'none';
                }}
              />
            </div>
          )}
        </div>
        <div className="guesses-container">
          <div className="guess-row header">
            <div className="guess-item">Name</div>
            <div className="guess-item">Brand</div>
            <div className="guess-item">Weight (g)</div>
            <div className="guess-item">Shape</div>
            <div className="guess-item">Thumb Rest?</div>
            <div className="guess-item">Wire</div>
            <div className="guess-item">DPI</div>
            <div className="guess-item">4K?</div>
            <div className="guess-item">Side Buttons</div>
            <div className="guess-item">Middle Buttons</div>
          </div>
          {renderGuesses()}
        </div>
        {gameOver && (
          <div className="game-over">
            <h2 style={{ color: guesses[guesses.length - 1].name.correct ? '#4caf50' : '#ff4444' }}>
              {guesses[guesses.length - 1].name.correct ? 'Congratulations!' : 'Nice Try!'}
            </h2>
            <p style={{ fontSize: '20px' }}>Today's mouse is the...</p>
            <p style={{ fontSize: '20px' }}>{dailyMouse.name}!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Game;